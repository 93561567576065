import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { INTERVAL_TYPE_TEXTS } from '../../../constants';
import { useStore } from '../../../hooks';
import { AddUserIcon, TrashCanIcon } from '../../../icons';
import { RoundingStepInstance } from '../../../models';
import { datesToString } from '../../../utils';
import { IntervalType, RoundingType } from '../../../types';
import {
  Daterangepicker,
  Combobox,
  Select,
  Label,
  InputWithError,
} from '../../../components';
import {
  endOfDay,
  startOfDay,
  endOfYear,
  startOfYear,
  addYears,
} from 'date-fns';
import { FacilityFormValues, RoundingStepForm } from '..';

interface RoundingStepsState {
  datePicker: { isOpen: boolean; index: number } | null;
  setDatePicker: (
    datePicker: { isOpen: boolean; index: number } | null,
  ) => void;
  handlePeriodChange: (dates: [Date, Date]) => void;
  dateRange: [Date, Date];
}

export const getRoundingStepFormValues = (
  roundingSteps: RoundingStepInstance[],
) => {
  return roundingSteps.map((step) => ({
    id: step.id,
    name: step.name,
    company: {
      name: step.companyName,
      value: step.company ? String(step.company.id) : '',
    },
    roundingType: {
      name: RoundingType[step.rounding_type],
      value: step.rounding_type,
    },
    setpoints: step.setpoints,
    instruction: step.instruction,
    period: {
      name: datesToString([step.from_dt, step.to_dt]),
      fromDate: step.from_dt,
      toDate: step.to_dt,
    },
    interval: String(step.interval),
    intervalType: {
      name: INTERVAL_TYPE_TEXTS[step.interval_type],
      value: step.interval_type,
    },
    isSaved: true,
  }));
};

export const RoundingSteps = observer(() => {
  const { control, setValue, getValues } = useFormContext<FacilityFormValues>();

  const {
    fields: roundingSteps,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'roundingSteps',
  });

  const state = useLocalObservable<RoundingStepsState>(() => ({
    datePicker: null,
    setDatePicker(datePicker) {
      state.datePicker = datePicker;
    },
    handlePeriodChange(dates) {
      if (!state.datePicker) return;

      setValue(`roundingSteps.${state.datePicker.index}.period`, {
        name: datesToString(dates),
        fromDate: dates[0],
        toDate: dates[1],
      });
      state.datePicker = null;
    },
    get dateRange(): [Date, Date] {
      const defaultRange = [startOfDay(new Date()), endOfDay(new Date())] as [
        Date,
        Date,
      ];

      if (!state.datePicker) return defaultRange;

      const period = getValues(
        `roundingSteps.${state.datePicker.index}.period`,
      );

      if (!period) return defaultRange;

      return [startOfDay(period.fromDate), endOfDay(period.toDate)];
    },
  }));

  return (
    <>
      <div className="block w-full rounded-md bg-white p-7 md:w-64">
        <span className="text-xl font-extrabold text-indigo-900">
          Rondering
        </span>
      </div>
      <div>
        <div className="mt-8 rounded-md bg-white lg:mt-12">
          {roundingSteps.length > 0 ? (
            <div className="w-full">
              {roundingSteps.map((roundingStep, index) => {
                return (
                  <RoundingStepFormRow
                    key={roundingStep.id}
                    index={index}
                    onRemove={() => remove(index)}
                    onShowDatePicker={() =>
                      state.setDatePicker({ isOpen: true, index })
                    }
                    disabled={roundingStep.isSaved}
                  />
                );
              })}
            </div>
          ) : (
            <div className="mx-4 flex-1 rounded-md bg-white px-7 py-7 text-center lg:mx-14 lg:py-28">
              <p className="text-2xl font-extrabold text-indigo-900 lg:text-3xl">
                Det finns inga ronderingar.
              </p>
            </div>
          )}
          <div className="p-5 md:px-14 md:pb-10 lg:py-10">
            <button
              type="button"
              onClick={() => append({ isSaved: false } as RoundingStepForm)}
              className="group flex w-full flex-grow items-center justify-center rounded-md bg-indigo-600 p-3 transition duration-200 hover:bg-indigo-900 md:w-auto"
            >
              <AddUserIcon fill="white" />
              <span className="px-2 text-left font-semibold text-white transition duration-200">
                Lägg till nytt ronderingssteg
              </span>
            </button>
          </div>
        </div>
      </div>
      {state.datePicker?.isOpen && (
        <div className="z-50 w-96 md:fixed md:left-1/2 md:top-1/4 md:-translate-x-1/2 md:-translate-y-1/4 md:transform">
          <Daterangepicker
            dateRange={state.dateRange}
            onApply={state.handlePeriodChange}
            onCancel={() => state.setDatePicker(null)}
            show
            dateFormatOptions={{ month: 'long' }}
            minDate={startOfYear(new Date())}
            maxDate={endOfYear(addYears(new Date(), 1))}
          />
        </div>
      )}
    </>
  );
});

interface RoundingStepFormRowProps {
  index: number;
  onRemove: (index: number) => void;
  onShowDatePicker: () => void;
  disabled?: boolean;
}

interface RoundingStepFormRowState {
  getErrorMessage: (
    field: keyof RoundingStepForm,
    errors: any,
  ) => string | undefined;
}

const RoundingStepFormRow: FC<RoundingStepFormRowProps> = observer(
  ({ index, onRemove, onShowDatePicker, disabled = false }) => {
    const {
      organizationStore: { organization },
    } = useStore();

    const {
      register,
      control,
      formState: { errors },
    } = useFormContext<FacilityFormValues>();

    const state = useLocalObservable<RoundingStepFormRowState>(() => ({
      getErrorMessage(field, errors): string {
        if (errors.roundingSteps && errors.roundingSteps[index]) {
          return errors.roundingSteps[index][field]?.message;
        }

        return '';
      },
    }));

    return (
      <div
        className={`relative flex w-full flex-col gap-5 p-5 py-10 even:bg-gray-50 md:px-14`}
      >
        <div className="absolute -top-5 left-4 flex h-10 w-11 flex-col items-center justify-center rounded-full bg-pink-500 text-white">
          {index + 1}
        </div>
        <div className="absolute right-0 top-5 flex h-10 w-11 sm:right-3">
          <button onClick={() => onRemove(index)}>
            <TrashCanIcon />
          </button>
        </div>
        <div className="flex w-full flex-col flex-wrap items-start gap-5 lg:flex-row">
          <div className="w-full sm:w-80">
            <Label>Namn</Label>
            <InputWithError
              placeholder="Namn"
              {...register(`roundingSteps.${index}.name`, {
                required: 'Fyll i detta fält',
                maxLength: {
                  value: 50,
                  message: 'Max 50 tecken',
                },
              })}
              disabled={disabled}
              error={state.getErrorMessage('name', errors)}
            />
          </div>
          <div className="w-full sm:w-80">
            <Label>Företag</Label>
            <Controller
              name={`roundingSteps.${index}.company`}
              control={control}
              rules={{ required: 'Fyll i detta fält' }}
              render={({ field: { value, onChange } }) => (
                <Combobox
                  items={organization!.companies.map((c) => ({
                    label: c.name,
                    value: String(c.id),
                  }))}
                  onSelect={(item) =>
                    onChange({ name: item.label, value: item.value })
                  }
                  disabled={disabled}
                  defaultValue={value?.name || ''}
                  error={state.getErrorMessage('company', errors)}
                  placeholder={disabled ? 'Inget företag' : 'Ange företag'}
                />
              )}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-5 lg:flex-row lg:flex-wrap">
          <div className="w-full sm:w-80">
            <Label>Ronderingstyp</Label>
            <Controller
              name={`roundingSteps.${index}.roundingType`}
              control={control}
              rules={{ required: 'Fyll i detta fält' }}
              render={({ field: { value, onChange } }) => (
                <Combobox
                  items={Object.keys(RoundingType).map((key) => ({
                    label: RoundingType[key as keyof typeof RoundingType],
                    value: key,
                  }))}
                  onSelect={(item) =>
                    onChange({ name: item.label, value: item.value })
                  }
                  disabled={disabled}
                  defaultValue={value?.name || ''}
                  error={state.getErrorMessage('roundingType', errors)}
                  placeholder="Ange en ronderingstyp"
                />
              )}
            />
          </div>
          <div className="w-full sm:w-80">
            <Label>Börvärden</Label>
            <InputWithError
              placeholder="Börvärden"
              {...register(`roundingSteps.${index}.setpoints`, {
                required: 'Fyll i detta fält',
                maxLength: {
                  value: 50,
                  message: 'Max 50 tecken',
                },
              })}
              disabled={disabled}
              error={state.getErrorMessage('setpoints', errors)}
            />
          </div>
          <div className="w-full sm:w-80">
            <Label>Instruktion</Label>
            <InputWithError
              placeholder="Instruktion"
              {...register(`roundingSteps.${index}.instruction`, {
                required: 'Fyll i detta fält',
              })}
              disabled={disabled}
              error={state.getErrorMessage('instruction', errors)}
            />
          </div>
          <div className="w-52 ">
            <Label>Period</Label>
            <Controller
              name={`roundingSteps.${index}.period`}
              control={control}
              rules={{ required: 'Fyll i detta fält' }}
              render={({ field: { value } }) => (
                <InputWithError
                  placeholder="1 Maj - 1 Juni"
                  onClick={onShowDatePicker}
                  autoComplete="off"
                  disabled={disabled}
                  value={value?.name || ''}
                  error={state.getErrorMessage('period', errors)}
                  readOnly
                />
              )}
            />
          </div>
          <div className="flex items-end gap-5">
            <div className="w-20">
              <Label>Intervall</Label>
              <InputWithError
                placeholder="1"
                type="number"
                {...register(`roundingSteps.${index}.interval`, {
                  required: 'Fyll i detta fält',
                  min: {
                    value: 1,
                    message: 'Minsta värdet är 1',
                  },
                })}
                disabled={disabled}
                error={state.getErrorMessage('interval', errors)}
              />
            </div>
            <div className="w-32">
              <Controller
                name={`roundingSteps.${index}.intervalType`}
                control={control}
                rules={{ required: 'Fyll i detta fält' }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    placeholder="Dag"
                    items={Object.values(IntervalType).map((intervalType) => ({
                      name: INTERVAL_TYPE_TEXTS[intervalType],
                      value: intervalType,
                    }))}
                    onSelect={onChange}
                    selected={value || null}
                    disabled={disabled}
                    error={state.getErrorMessage('intervalType', errors)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
