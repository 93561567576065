import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeletePopup,
  FadeInDiv,
  InputWithError,
  Label,
  AdditionalInformation,
} from '../../components';
import { SaveButton } from '../../components/SaveButton';
import { SAVE_BUTTON_ANIMATION_DURATION } from '../../constants';
import { useStore } from '../../hooks';
import { TrashCanIcon } from '../../icons';
import { FacilityInstance } from '../../models';
import { FacilityMetaData, SubmitState } from '../../types';
import {
  Assignments,
  RoundingSteps,
  getRoundingStepFormValues,
} from './components';

interface FacilityParams {
  facilityId: string;
}

export const EditFacilityPage = observer(() => {
  const {
    organizationStore: { organization },
  } = useStore();
  const params = useParams<keyof FacilityParams>() as FacilityParams;

  const facilityId = parseInt(params.facilityId, 10);
  const facility = organization?.facilities.find((f) => f.id === facilityId);

  if (!facility) return null;

  return <EditFacility facility={facility} />;
});

export interface RoundingStepForm {
  readonly id?: number;
  name: string;
  company: { name: string; value: string };
  roundingType: { name: string; value: string };
  setpoints: string;
  instruction: string;
  interval: string;
  intervalType: { name: string; value: string };
  period: { name: string; fromDate: Date; toDate: Date };
  isSaved: boolean;
}

export interface FacilityAssignment {
  id?: string;
  user: number;
  issue_types: number[];
  rounding_types?: string[];
}

export interface FacilityFormValues {
  facilityName: string;
  assignments: FacilityAssignment[];
  roundingSteps: RoundingStepForm[];
  metaData: { key: string; value: string }[];
}

interface EditFacilityState {
  submitState: SubmitState;
  setSubmitState: (submitState: SubmitState) => void;
  onSubmit: (formValues: FacilityFormValues) => void;

  isDeletePopupOpen: boolean;
  toggleDeletePopup: () => void;

  handleDeleteFacility: () => void;
}

const EditFacility = observer(
  ({ facility }: { facility: FacilityInstance }) => {
    const formMethods = useForm<FacilityFormValues>({
      shouldUnregister: false,
      reValidateMode: 'onSubmit',
      defaultValues: {
        facilityName: facility.name,
        assignments: facility.assignments.map((assignment) => ({
          user: assignment?.user?.id,
          issue_types: assignment.issue_types.map((issueType) => issueType?.id),
          rounding_types: assignment.rounding_types,
        })),
        roundingSteps: getRoundingStepFormValues(
          facility.filteredRoundingSteps,
        ),
        metaData: facility.meta_data
          ? Array.from(facility.meta_data.keys()).map((key) => ({
              key,
              value: facility.meta_data?.get(key) || '',
            }))
          : undefined,
      },
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
      control,
      reset,
    } = formMethods;

    const navigate = useNavigate();
    const {
      organizationStore: { organization },
    } = useStore();

    const state = useLocalObservable<EditFacilityState>(() => ({
      submitState: SubmitState.None,
      setSubmitState(submitState) {
        state.submitState = submitState;
      },
      async onSubmit(formValues) {
        await facility.save(formValues);

        reset({
          ...formValues,
          roundingSteps: formValues.roundingSteps?.map((roundingStep) => ({
            ...roundingStep,
            isSaved: true,
          })),
        });

        state.setSubmitState(SubmitState.Success);
        setTimeout(() => {
          state.setSubmitState(SubmitState.None);
        }, SAVE_BUTTON_ANIMATION_DURATION);
      },
      isDeletePopupOpen: false,
      toggleDeletePopup() {
        state.isDeletePopupOpen = !state.isDeletePopupOpen;
      },
      async handleDeleteFacility() {
        await facility.deactivate();
        navigate('/organization/facilities');
      },
    }));

    return (
      <FadeInDiv className="relative p-4 md:p-14">
        <div className="block rounded-md bg-white p-7 md:inline-block">
          <span className="text-xl font-extrabold text-indigo-900">
            Redigera fastighet
          </span>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(state.onSubmit)}>
            <div className="mt-5 rounded-md bg-white p-7 md:mt-10">
              <div className="max-w-80">
                <div className="flex flex-col">
                  <Label required>Namn</Label>
                  <InputWithError
                    type="text"
                    placeholder="Namn"
                    {...register('facilityName', {
                      required: 'Fyll i detta fältet',
                      maxLength: {
                        value: 50,
                        message: 'Max 50 tecken',
                      },
                    })}
                    error={errors.facilityName?.message}
                  />
                </div>
              </div>
              <div className="mt-10">
                <Assignments />
              </div>
            </div>
            {organization?.hasEntreFeature && (
              <div className="mt-5 md:mt-7 2xl:max-w-1/2">
                <Controller
                  control={control}
                  name="metaData"
                  render={({ field: { onChange } }) => (
                    <AdditionalInformation
                      fields={Object.values(FacilityMetaData).map((key) => ({
                        key,
                        value: facility.meta_data?.get(key) || '',
                      }))}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )}
            <div className="mt-5 flex items-center md:mt-7">
              <SaveButton
                disabled={state.submitState !== SubmitState.None}
                isSaved={state.submitState === SubmitState.Success}
              />
              <button
                type="button"
                className="ml-5 flex items-center justify-center rounded-md bg-rose-300 p-3 transition duration-200 hover:bg-rose-500"
                onClick={state.toggleDeletePopup}
              >
                <TrashCanIcon className="w-4 text-white" />
                <span className="ml-3 font-semibold text-white">Radera</span>
              </button>
            </div>

            {organization?.hasRoundsFeature && (
              <div className="pt-12 md:pt-20">
                <RoundingSteps />
                <div className="mt-5 flex items-center md:mt-7">
                  <SaveButton
                    disabled={
                      isSubmitting || state.submitState === SubmitState.Success
                    }
                    isSaved={state.submitState === SubmitState.Success}
                  />
                </div>
              </div>
            )}
          </form>
        </FormProvider>
        {state.isDeletePopupOpen && (
          <DeletePopup
            title="Radera fastighet"
            onConfirm={state.handleDeleteFacility}
            onDismiss={state.toggleDeletePopup}
          >
            Om du raderar fastigheten går det inte att återställas. Är du säker
            på att du vill radera fastigheten?
          </DeletePopup>
        )}
      </FadeInDiv>
    );
  },
);
